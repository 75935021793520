<template>
  <page-title
    class="q-mb-lg"
    :title="$t('account.account')"
    video_url="https://www.youtube.com/embed/KiF4pyDHciQ"
  >
  </page-title>

  <q-intersection
    class="full-height default-shadow default-rounded bg-white full-height-intersection"
    transition="slide-up"
  >
    <div class="text-right q-pr-md q-pt-md">
      <q-btn
        dense
        flat
        color="blue"
        @click="donloadLastTerm"
        icon="sim_card_download"
        label="Baixar termo assinado"
        :loading="in_request_download"
        style="font-size: 11px"
      />
    </div>
    <Form
      class="q-gutter-md"
      :initial-values="initialValues"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <q-card-section class="row form-inputs-content justify-between">
        <Field
          v-for="(structure, key) in structureForm"
          :key="key"
          :name="structure.name"
          v-slot="{ errorMessage, value, field }"
        >
          <div
            class="q-pa-md bg-grey-4 default-rounded q-mt-sm q-mb-md full-width"
            v-if="structure.field_message"
            v-html="structure.field_message"
          />
          <q-select
            v-if="structure.type === 'select'"
            style="width: 100%"
            outlined
            :dense="$q.platform.is.mobile"
            filled
            clearable
            bottom-slots
            v-bind="field"
            :type="structure.type"
            :mask="structure.mask"
            :counter="!!structure.maxlength"
            :maxlength="structure.maxlength"
            :model-value="value"
            :label="structure.label"
            :error-message="errorMessage"
            :error="!!errorMessage"
            :options="structure.options"
          >
            <template v-slot:before>
              <q-icon :name="structure.icon" />
            </template>
          </q-select>
          <q-input
            v-else
            :disable="isFieldDisabled(key, value, errorMessage)"
            style="width: 100%"
            class="q-mb-sm"
            filled
            bottom-slots
            :dense="$q.platform.is.mobile"
            clearable
            :stack-label="structure['stack-label']"
            v-bind="field"
            :type="structure.type"
            :mask="
              structure.label == 'Telefone'
                ? getPhoneMask(value)
                : structure.mask
            "
            :counter="!!structure.maxlength"
            :maxlength="structure.maxlength"
            :model-value="
              structure.label == 'CPF' ? formatCpfWithCaracters(value) : value
            "
            :label="structure.label"
            :error-message="
              structure.label == 'CPF'
                ? verifyCpfInvalid(errorMessage, value)
                : errorMessage
            "
            :error="
              structure.label == 'CPF'
                ? !!verifyCpfInvalid(errorMessage, value)
                : !!errorMessage
            "
          >
            <template v-slot:before>
              <q-icon :name="structure.icon" />
            </template>
          </q-input>
        </Field>
      </q-card-section>
      <q-card-section class="row q-mt-none q-pt-none">
        <q-btn
          color="white"
          outline
          class="full-width bg-grey-10 q-mt-sm q-pa-sm q-pr-lg q-pl-lg"
          type="submit"
          :label="$t('global.save')"
          :loading="in_request"
          :disable="in_request"
        />
      </q-card-section>
    </Form>
  </q-intersection>
</template>

<script>
import {
  labels,
  getStructure,
  build,
  getMappedFields,
  buildInitialValues,
  getFormValuesChanged,
} from "@/shared/form-presets";
import { validateCPF } from "validations-br";
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { useGetters, useActions } from "vuex-composition-helpers";
import {
  getPersonalInfoFields,
  getPhoneMask,
  formatCpfWithCaracters,
} from "./setup";
import PageTitle from "@/modules/main/components/page-title";
import { Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { ref } from "vue";

export default {
  name: "PersonalInfo",

  components: {
    PageTitle,
    Field,
    Form,
  },

  setup() {
    const { t } = useI18n();

    const in_request = ref(false);
    const { partner, getDownloadLastTermConf, partnerId } = useGetters({
      partner: "account/getPartnerAccount",
      getDownloadLastTermConf: "account/getDownloadLastTermConf",
      partnerId: "home/getPartnerId",
    });

    const { physic_type, juridic_type } = getPersonalInfoFields();

    let isJuridic =
      partner.value.pf_pj &&
      partner.value.pf_pj.toString().toUpperCase() === "PJ";

    const fieldsDefinitions = {
      ...(isJuridic ? juridic_type : physic_type),
    };

    const structureForm = getStructure(Object.keys(fieldsDefinitions)),
      schema = build(getMappedFields(Object.keys(fieldsDefinitions)));

    let initialValues = buildInitialValues(
      fieldsDefinitions,
      structureForm,
      partner.value
    );

    const {
      updateUserPartnerAction,
      fetchDownloadLastSignedTerm,
      fetchUserAction,
    } = useActions({
      updateUserPartnerAction: "account/updateUserPartnerAction",
      fetchDownloadLastSignedTerm: "account/fetchDownloadLastSignedTerm",
      fetchUserAction: "auth/fetchUserAction",
    });

    const onSubmit = (values) => {
      let payload = getFormValuesChanged(
        fieldsDefinitions,
        initialValues,
        values
      );

      if (Object.keys(payload).length > 0) {
        in_request.value = true;

        if ("pf_cpf" in payload)
          payload.pf_cpf = payload.pf_cpf.replace(/\D/g, "");

        updateUserPartnerAction(payload)
          .then(() => {
            initialValues = values;
            notifySuccess("actions.data_changed_success");
          })
          .catch(({ errors, message }) => {
            const notify = (error) => notifyError(error, false);

            if (errors) Object.values(errors).forEach(notify);
            if (message) notify(message);
          })
          .finally(() => {
            in_request.value = false;
            fetchUserAction();
          });
      } else {
        notifySuccess("actions.data_changed_success");
      }
    };

    const in_request_download = ref(false);
    const donloadLastTerm = () => {
      in_request_download.value = true;
      fetchDownloadLastSignedTerm({
        data: {
          partner_id: partnerId.value,
        },
        conf: getDownloadLastTermConf.value,
      }).finally(() => (in_request_download.value = false));
    };

    let isCpfInvalid = ref(false);

    const isFieldDisabled = (key, value, errorMessage) => {
      if (key === "document_cpf_no_required") {
        if (errorMessage) return false;

        let condition =
          value &&
          validateCPF(value) &&
          formatCpfWithCaracters(value).length === 14;

        isCpfInvalid.value = condition ? false : true;

        return condition;
      }
    };

    const verifyCpfInvalid = (errorMessage, value) => {
      if (isCpfInvalid.value)
        return errorMessage
          ? errorMessage
          : value
          ? t("account.cpf_is_invalid")
          : t("account.cpf_is_required");
      return errorMessage;
    };

    return {
      labels,
      schema,
      in_request,
      initialValues,
      structureForm,
      onSubmit,
      getPhoneMask,
      isCpfInvalid,
      verifyCpfInvalid,
      formatCpfWithCaracters,
      fieldsDefinitions,
      donloadLastTerm,
      in_request_download,
      isFieldDisabled,
    };
  },
};
</script>
