export const getPartnerTypes = (partner_type) => {
  const type = partner_type == "PJ" ? "cpf" : "cnpj";

  return [
    {
      label: "E-mail",
      value: "email",
      name: "email_pix",
    },
    {
      label: "CPF",
      value: "cpf",
      name: "document_cpf_pix",
    },
    {
      label: "CNPJ",
      value: "cnpj",
      name: "document_pj_pix",
    },
    {
      label: "Número do Celular",
      value: "phone",
      name: "phone_pix",
    },
    {
      label: "Chave Aleatoria",
      value: "random",
      name: "random_key",
    },
  ].filter((el) => el.value != type);
};

export const getFieldsDefinitionsBank = () => ({
  bank: "bank_code",
  agency: "agency",
  bank_type: "type",
  bank_number: "current_account",
  bank_number_dv: "current_account_dv",
});

export const getFieldsDefinitionsPix = () => ({
  email_pix: "email",
  document_cpf_pix: "cpf",
  document_pj_pix: "cnpj",
  phone_pix: "phone",
  random_key: "random",
});

export const getPhoneMask = (value) => {
  if (value) {
    return value.length == 14 ? "(##) ####-#####" : "(##) #####-####";
  } else return "(##) #####-####";
};

export const formatCpfWithCaracters = (value) => {
  if (value) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }
  return value;
};

export const getPersonalInfoFields = () => ({
  physic_type: {
    complete_name: "nome",
    phone: "fone",
    celular: "celular",
    document_cpf_no_required: "pf_cpf",
    document_rg: "pf_rg",
    pf_pis_pasep: "pf_pis_pasep",
    pf_nascimento: "pf_nascimento",
    parent_name: "pf_nome_pai",
    mother_name: "pf_nome_mae",
    uf_birth: "pf_nasc_uf",
    city_birth: "pf_cidade_nascimento",
    education: "pf_nasc_escolaridade",
    sex: "sexo",
    ethnicity: "ethnicity_id",
  },
  juridic_type: {
    fantasy_name: "pj_fantasia",
    social_name: "pj_razaosocial",
    document_pj_no_required: "pj_cnpj",
    document_ie: "pj_ie",
    phone: "fone",
    celular: "celular",
    sex: "sexo",
    nome: "pj_nome_contato",
    email_contact: "pj_email_contato",
    document_cpf_contact: "pf_cpf", // "pj_cpf_contato",
  },
});
